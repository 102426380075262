import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c913905c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "appointment-result" }
const _hoisted_2 = { class: "appointment-result__box" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        innerHTML: _ctx.t('APPOINTMENT_RESULT.HEADER')
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_a_divider),
      _createVNode(_component_a_spin, {
        class: "appointment-result__spinner",
        indicator: _ctx.indicator
      }, null, 8 /* PROPS */, ["indicator"]),
      _createElementVNode("p", null, _toDisplayString(_ctx.t("APPOINTMENT_RESULT.BODY")), 1 /* TEXT */)
    ])
  ]))
}