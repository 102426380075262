
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { defineComponent, onMounted, inject, h } from "vue";
import { AxiosStatic } from "axios";
import { LoadingOutlined } from "@ant-design/icons-vue";

export const AppointmentResultComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: "64px",
      },
      spin: true,
    });
    const checkPayment = () => {
      return http
        .get(
          `patient-portal/api/payment/check-status?appointmentId=${props.id}`
        )
        .then((res) => res.data);
    };

    onMounted(() => {
      const checkPaymentInterval = setInterval(async () => {
        const status: string = await checkPayment();
        switch (status) {
          case "ACCEPTED": {
            router.push({
              name: "AppointmentSuccess",
              params: { id: props.id },
            });
            clearInterval(checkPaymentInterval);
            break;
          }
          case "REJECTED": {
            router.push({
              name: "AppointmentFailed",
              params: { id: props.id },
            });
            clearInterval(checkPaymentInterval);
            break;
          }
        }
      }, 6000);
    });

    return { t, indicator };
  },
});
export default AppointmentResultComponent;
